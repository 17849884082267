<template>
  <div>
    <div v-if="lang == 'ka'">
      <vue-headful
        :title="[removeHTMLForTitle(singleVacancy.category.titleKA)]"
        :description="[removeHTMLForTitle(singleVacancy.descriptionKA)]"
        :keywords="singleVacancy.keywords.toString()"
        image=""
        lang=""
        ogLocale=""
        url=""
      />
    </div>
    <div v-else>
      <vue-headful
        :title="[removeHTMLForTitle(singleVacancy.category.titleKA)]"
        :description="[removeHTMLForTitle(singleVacancy.descriptionEN)]"
        :keywords="singleVacancy.keywords.toString()"
        image=""
        lang=""
        ogLocale=""
        url=""
      />
    </div>

    <Navbar />

    <div class="pl-0 vacancyReadingContainer mt-5 mb-5">
      <div class="col pl-0">
        <PathPage class="pb-2" />
        <div class="row pl-0 pr-0 ml-0 mr-0">
          <div class="col-sm-12 col-md-9 pl-0">
            <b-skeleton-img
              v-if="isLoading"
              no-aspect
              height="2.5rem"
              width="400px"
              style="max-width: '100%"
            ></b-skeleton-img>
            <h3 v-else class="blackColor dejavuBOLD">
              <span
                class="blackColor dejavuBOLD"
                v-if="lang == 'ka'"
                v-html="singleVacancy.category.titleKA"
              ></span>
              <span
                class="blackColor dejavuBOLD"
                v-else
                v-html="singleVacancy.category.titleEN"
              ></span>
            </h3>

            <div class="pt-2 blackColor">
              <div class="d-flex">
                <p style="text-transform: uppercase">
                  {{ $t("vacancyElements.location") }}:
                </p>
                <div
                  v-if="isLoading"
                  style="margin-top: 5px; margin-left: 20px"
                >
                  <b-skeleton-img
                    no-aspect
                    height="1rem"
                    width="100px"
                  ></b-skeleton-img>
                </div>
                <h4 v-else>
                  <span
                    style="text-transform: uppercase"
                    class="pl-2 blackColor dejavuBOLD"
                    v-if="lang == 'ka'"
                    v-html="singleVacancy.location.titleKA"
                  >
                  </span>
                  <span
                    style="text-transform: uppercase"
                    class="pl-2 blackColor dejavuBOLD"
                    v-html="singleVacancy.location.titleEN"
                    v-else
                  >
                  </span>
                </h4>
              </div>
              <div class="d-flex" style="border-bottom: 1px solid #4d4d4c">
                {{ $t("vacancyElements.startDate") }}
                <div
                  v-if="lang == 'ka'"
                  style="font-family: mtavruliBOLD"
                  class="pl-2"
                >
                  {{ singleVacancy.startDateKA }} -
                  {{ singleVacancy.endDateKA }}
                </div>
                <div v-else style="font-family: mtavruliBOLD" class="pl-2">
                  {{ singleVacancy.startDateEN }} -
                  {{ singleVacancy.endDateEN }}
                </div>
              </div>

              <div class="pt-4">
                <b-skeleton-img
                  v-if="isLoading"
                  no-aspect
                  height="15rem"
                  type="input"
                ></b-skeleton-img>
                <p
                  class="mb-0"
                  v-if="lang == 'ka'"
                  v-html="singleVacancy.descriptionKA"
                ></p>
                <p class="mb-0" v-else v-html="singleVacancy.descriptionEN"></p>
              </div>
            </div>
          </div>
          <div
            class="
              sharingSPace
              col-sm-12 col-md-3
              d-flex
              justify-content-center
            "
          >
            <div class="row d-flex justify-content-center">
              <div>
                <div class="d-flex">
                  <button
                    style="width: 200px; text-transform: uppercase"
                    type="submit"
                    class="btn btn-primary pt-1 pb-1"
                    @click="showModal()"
                  >
                    <form style="text-transform: uppercase">
                      <input
                        hidden
                        id="fileUpload"
                        class="ml-0 mr-0"
                        placeholder="file"
                        style="width: 100%; color: white"
                        type="file"
                        onchange="this.form.submit()"
                      />{{ $t("vacancyElements.uploadResume") }}
                    </form>
                  </button>
                  <b-modal
                    v-model="modalShow"
                    ok-only
                    :title="singleVacancy.category.titleKA"
                    ok-variant="secondary"
                    :ok-title="$t('front.cancel')"
                  >
                    <ResumeUploadTitle
                      :modalShow="showModal"
                      :item="singleVacancy"
                    />
                  </b-modal>
                </div>
                <div class="p-0 m-0 d-flex justify-content-center">
                  <p class="align-middle pt-1 pb-0 mb-0">
                    {{ $t("vacancyElements.or") }}
                  </p>
                </div>
                <div class="d-flex justify-content-center">
                  <router-link to="/vacancyForm"
                    ><button
                      style="width: 200px; text-transform: uppercase"
                      type="button"
                      class="btn btn-primary1 pt-1 pb-1"
                    >
                      {{ $t("vacancyElements.fillForm") }}
                    </button></router-link
                  >
                </div>
                <div class="pt-3">
                  <div>
                    <div
                      class="
                        d-flex
                        justify-content-center
                        sharingSide
                        align-items-center
                        mtavruli
                        pt-1
                        pb-1
                        blackColor
                      "
                    >
                      <span class="pr-3 mtavruli">{{ $t("front.share") }}</span>
                      <div v-if="lang == 'ka'" class="d-flex">
                        <ShareNetwork
                          v-for="network in networks"
                          :network="network.network"
                          :key="network.network"
                          :url="shareNetwork"
                          :title="singleVacancy.category.titleKA"
                          :quote="
                            joinStrings([
                              removeHTML(vacancyKA),
                              removeHTML(singleVacancy.category.titleKA),
                              removeHTML(singleVacancy.location.titleKA),
                              removeHTML(singleVacancy.descriptionKA),
                            ])
                          "
                        >
                          <div>
                            <img
                              style="width: 31px"
                              class="p-1"
                              :src="network.icon"
                              :alt="
                                lang == 'ka'
                                  ? 'სოციალური ქსელი'
                                  : 'social network'
                              "
                            />
                          </div>
                        </ShareNetwork>
                      </div>
                      <div v-else class="d-flex">
                        <ShareNetwork
                          v-for="network in networks"
                          :network="network.network"
                          :key="network.network"
                          :url="shareNetwork"
                          :title="singleVacancy.category.titleEN"
                          :quote="
                            joinStrings([
                              removeHTML(vacancyEN),
                              removeHTML(singleVacancy.category.titleEN),
                              removeHTML(singleVacancy.location.titleEN),
                              removeHTML(singleVacancy.descriptionEN),
                            ])
                          "
                        >
                          <div>
                            <img
                              style="width: 31px"
                              class="p-1"
                              :src="network.icon"
                              alt=""
                            />
                          </div>
                        </ShareNetwork>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import image from "../../../../assets/icons/facebook7.png";
import imagelink from "../../../../assets/icons/in7.png";
import env from "../../../../env.json";
import PathPage from "../../PathPage.vue";
import axios from "axios";
import ResumeUploadTitle from "../VacancyElements/ResumeUploadTitle.vue";
export default {
  components: {
    Navbar,
    Footer,
    PathPage,
    ResumeUploadTitle,
  },
  data() {
    return {
      modalShow: false,
      lang: "ka",
      vacancyKA: "ვაკანსია",
      vacancyEN: "Vacancy",
      locationtitleKA: "ადგილმდებაროება",
      locationtitleEN: "Location",

      singleVacancy: {},
      isLoading: true,
      shareNetwork: env.shareNetwork,
      sharing: {
        url: "https://smart-pharm-project.netlify.app/blog/:id",
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript",
        twitterUser: "youyuxi",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: image,
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: imagelink,
          color: "#007bb5",
        },
      ],
    };
  },
  methods: {
    showModal: function () {
      this.modalShow = !this.modalShow;
    },
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    removeHTMLForTitle: function (string) {
      return string.replace(/<\/?[^>]+>/gi, "");
    },
    joinStrings: function (string) {
      return string.join(" - ");
    },
  },
  mounted() {
    axios
      .get(
        `${env.host}/get/once/career/${this.$route.params.slug}/null/null/null`
      )
      .then((result) => {
        this.singleVacancy = result.data.item;
        this.isLoading = false;
        this.shareNetwork = `${env.social_network_share}/career/${this.$route.params.slug}`;
        console.log(this.shareNetwork);
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.form-control {
  border-radius: 0px;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0px;
  outline: 0;
  margin-top: 250px !important;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active {
  background-color: none;
  border-color: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e54939f;
  border-color: #7e54939f;
}
.tabsIcons {
  width: 50px;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  /* border-color: #7e5493; */
  padding-left: 20px;
  margin: auto;
  border: none;
}

.vacancyReadingContainer {
  min-height: calc(100vh - 258px);
  width: 100%;
  max-width: 1400px;
  margin: auto;
  display: flex;
  align-items: center;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  border-color: #7e5493;

  /* border-color: #7e5493; */
  padding-left: 20px;
  margin: auto;
  border: none;
  color: white;
}
.btn-primary1 {
  background-color: #a4a8ab;
  border-radius: 0px;
  color: white;
  padding-left: 20px;
  margin: auto;
  border: none;
  color: white;
}
.sharingSide {
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;
}

@media all and (max-width: 1500px) {
  .vacancyReadingContainer {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media all and (max-width: 767px) {
  .sharingSPace {
    margin-top: 30px;
  }
}
</style>
