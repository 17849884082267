<template>
  <div style="max-width: 100%">
    <div>
      <form action="" @submit.prevent="submitFile">
        <div class="container">
          <input
            style="width: 200px"
            accept=".doc, .docx, .ppt, .pptx, .txt,.pdf"
            type="file"
            id="file"
            @change="previewFiles($event)"
          />
          <b-button
            type="submit"
            class="btn btn-primary"
            style="margin-top: auto"
          >
            {{ $t("front.upload") }}
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import env from "../../../../env.json";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      form: {
        image: null,
      },
    };
  },
  props: ["modalShow", "item"],
  methods: {
    previewFiles(e) {
      const image = e.target.files[0];
      this.form.image = image;
    },
    async submitFile() {
      console.log(this.item);
      const formData = new FormData();
      formData.append("image", this.form.image);
      formData.append("title", this.item);
      console.log(formData);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success1",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      if (this.form.image) {
        const resp = await axios.post(
          `${env.host}/upload/career/resume`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log(resp);
        if (resp.status < 400) {
          swalWithBootstrapButtons.fire({
            text: this.$t("front.succes"),
          });
          this.modalShow();
          console.log(this.modalShow);
        } else {
          this.$swal({
            type: "error",

            text: this.$t("front.error"),
          });
          this.error = err;
        }
      } else {
        swalWithBootstrapButtons.fire({
          text: this.$t("front.chooseFile"),
        });
      }
    },
  },
};
</script>
<style >
.btn-success1 {
  background-color: #7e54939f;
  color: white;
}

.btn-success1:hover {
  background-color: #7e5493;
  color: white;
}
::v-deep.btn-success1:focus {
  box-shadow: none !important;
}
.btn.focus,
.btn:focus {
  /* outline: 0; */
  box-shadow: none;
}
.btn {
  border-radius: 0px;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 31em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 0px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
</style>


<style scoped>
::v-deep.btn-success1:focus {
  box-shadow: none !important;
}

::v-deep.btn-danger {
  background-color: red !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none;
}
.container {
  display: flex;

  justify-content: space-between;

  padding: 0px;
  max-width: 100%;
}
.ModalButtons {
  display: flex;
  justify-content: space-around;
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 0px !important;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}

.btn-secondary {
  color: #fff;
  background-color: #7e5493;
  border-color: #7e5493;
}
.btn {
  border-radius: 0px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e5493;
  border-color: #005cbf00;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
/* @media all and (max-width: 453px) {
  .container {
    display: flex;
    flex-direction: column;
  }
} */
</style>